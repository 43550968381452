import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
// import { Field } from "formik";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface customInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  width?: any;
  padding?: any;
  height?: number;
  placeholder?: string;
  error?: string | null;
  background?: string;
  value?: string;
  label?: string;
  border?: string;
  onChange?: any;
  disabled?: any;
  required?: boolean;
  borderRadius?: any;
  focusBorderColor?: any;
}

export default function CustomInput(props: customInputProps) {
  const {
    type,
    placeholder,
    name,
    value,
    required,
    disabled,
    onChange,
    label,
    id,
    error,
  } = props;
  const [passwordType, SetPasswordType] = useState("password");
  const [show, setShow] = useState(true);
  const handleClick = () => {
    setShow(!show);
    if (passwordType === "password") {
      SetPasswordType("text");
    } else {
      SetPasswordType("password");
    }
  };

  return (
    <InputGroup
      size="2xl"
      width="100%"
      display="flex"
      flexDirection="column"
      w="100%"
      marginBottom="12px"
    >
      <label className="input-label">{label}</label>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        background="#F1F1F1"
        paddingTop="8px"
        paddingBottom="8px"
        paddingRight="10px"
        borderRadius="12px"
        overflow="hidden"
      >
        <Input
          onChange={onChange}
          name={name}
          id={id}
          value={value}
          type={type === "password" ? passwordType : type}
          disabled={disabled ? disabled : null}
          pr="4.5rem"
          _placeholder={{ opacity: 1, color: "gray.500" }}
          placeholder={placeholder ? placeholder : ""}
          background="#F1F1F1"
          border="none"
          focusBorderColor="#F1F1F1"
          width="100%"
          height="100%"
          padding="10px"
          outline={0}
        />

        {type === "password" && (
          <InputRightElement style={{ position: "relative" }}>
            <div onClick={handleClick}>
              {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          </InputRightElement>
        )}
      </Box>
      {error && <div className="error">{error}</div>}
    </InputGroup>
  );
}
