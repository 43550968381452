import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";

const useForgetPassword = () => {
  const user = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const forgetPassword = async (values: FormikValues) => {
    setIsLoading(true);
    await axiosBaseInstance
      .post("Onboarding/Forget-Password", values, {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.data.isCodeSent) {
          setIsSuccess(true);
          localStorage.setItem("idToken", res.data.data.idToken);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        showNotification({
          message:
            err.response.data.message || err.message || "An error occurred",
          color: "yellow",
        });
      });
  };
  return { forgetPassword, isLoading, isSuccess };
};

export default useForgetPassword;
