import { Box, Flex, Image, Text } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import email from "../../../assets/images/email.png";
import { Link } from "react-router-dom";
import { Button } from "../../../components"; 
import useResendCode from "../../../hooks/auth-hooks/useResendCode";
import { Loader } from "@mantine/core";

const SocialMail = () => {
  const navigate = useNavigate();
  const [isResendingCode, setIsResendingCode] = useState(false);

  const { resendCode } = useResendCode();
  const emailAddress = localStorage.getItem("email");
  const handleResendCode = () => {
    setIsResendingCode(true);
    const token = localStorage.getItem("idtoken");
    if (token) {
      resendCode(token).then(() => {
        setIsResendingCode(false);
      });
    }
  };
  return (
    <Box w="100%" height="100vh">
      <Flex
        justifyContent="center"
        align="center"
        flexDirection="column"
        height="100%"
        w="100%"
      >
        <Box w="95%" height="100%">
          <Flex
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            w="100%"
            height="100%"
          >
            <Box w="100%">
              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Image objectFit="contain" w={250} src={email} />
                <Box textAlign="center">
                  <Text fontSize="24px">Email Confirmation</Text>
                </Box>
                <Box textAlign="center" marginTop={4}>
                  <Text fontSize="14px">
                    We have sent an email to your mail
                    to activate your account. Please check your inbox or spam
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box w="100%">
              <Flex justifyContent="center">
                <Text>Didn't get our email?</Text>
                {/* <Typography> </Typography> */}

                <Text
                  marginLeft="5px"
                  color="green"
                  cursor="pointer"
                  onClick={handleResendCode}
                >
                  {isResendingCode ? (
                    <Loader color="#4cb051" size="sm" />
                  ) : (
                    "Send it again"
                  )}
                </Text>
              </Flex>

              <Box w="100%" marginTop={4}>
                <Link to="/socials-verify-identity">
                  <Button
                    variant="primary"
                    type="button"
                    size="normal"
                    style={{ marginBottom: "12px" }}
                  >
                    Continue
                  </Button>
                </Link>
                
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default SocialMail;
