import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

interface props {
  children: React.ReactNode;
}
const SubAuthLayout = () => {
  return (
    <Box
      sx={{
        "@media screen and (min-width: 450px)": {
          background: "#FFFFFF",
          boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
          padding: "20px 40px",
          marginTop: "30px",
          marginBottom: "30px",
        },
      }}
    >
      <Outlet />
    </Box>
  );
};

export default SubAuthLayout;
