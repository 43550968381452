import React from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const useEditWhatsAppTv = () => {
  const user = useSelector((state: RootState) => state.user);

  const editWhatsAppTv = async (values: FormikValues) => {
    return await axiosBaseInstance
      .post("/WhatsappTv/updatewhatsapptv", values, {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  return { editWhatsAppTv };
};

export default useEditWhatsAppTv;
