import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FormikValues } from "formik";

import useFetchUserProfile from "./useFetchUserProfile";

export default function useUpdateProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const user = useSelector((state: RootState) => state.user);
  const { fetchProfile } = useFetchUserProfile();

  const updateProfile = async (values: FormikValues) => {
    const form_values = { ...values };

    const formData = new FormData();

    Object.keys(form_values).forEach((key) => {
      formData.append(key, form_values[key]);
    });
    setIsLoading(true);

    return await axiosBaseInstance
      .post("/MyProfile/UpdateProfile", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then(async (res) => {
        setIsLoading(false);
        if (res.data.status === true) {
          showNotification({
            title: "Success",
            message: "Profile successfully updated",
            color: "green",
          });

          await fetchProfile();

          // localStorage.setItem("hasVisited", "true");
        }

        return res;
      })
      .catch((error: any) => {
        setIsLoading(false);
        setErrors(error.message);

        showNotification({
          title: "Error",
          message:
            error?.response?.data?.title ||
            error?.message ||
            "An error occurred. Try again",
          color: "red",
        });

        return error;
      });
  };

  return { isLoading, errors, updateProfile };
}
