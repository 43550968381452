import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Typography from "../core/typo/Typography";
import { Button } from "..";
import email from "../../assets/images/email.png";
import useResendCode from "../../hooks/auth-hooks/useResendCode";
import { Link } from "react-router-dom";

const EmailConfirmation = () => {
  const { resendCode } = useResendCode();

  const handleResendCode = () => {
    const token = localStorage.getItem("idToken");

    if (token) {
      resendCode(token);
    }
  };

  return (
    <Box w="100%" height="100vh">
      <Flex
        justifyContent="center"
        align="center"
        flexDirection="column"
        height="100%"
        w="100%"
      >
        <Box w="95%" height="100%">
          <Flex
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            w="100%"
            height="100%"
          >
            <Box w="100%">
              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Image objectFit="contain" w={250} src={email} />
                <Box textAlign="center">
                  <Typography fontSize="24px">Email Confirmation</Typography>
                </Box>
                <Box textAlign="center" marginTop={4}>
                  <Typography fontSize="14px">
                    We have sent an email to{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Johndoe@gmail.com
                    </span>{" "}
                    to activate your account. Please check your inbox or spam
                  </Typography>
                </Box>
              </Flex>
            </Box>
            <Box w="100%">
              <Flex justifyContent="center">
                <Typography>Didnt get our email?</Typography>
                {/* <Typography> </Typography> */}
                <Text marginLeft="5px" color="green" onClick={handleResendCode}>
                  {" "}
                  Send it again
                </Text>
              </Flex>

              <Box w="100%" marginTop={4}>
                <Link to="/verify-identity">
                  <Button
                    variant="primary"
                    type="button"
                    size="normal"
                    style={{ marginBottom: "12px" }}
                  >
                    Continue
                  </Button>
                </Link>
                "
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default EmailConfirmation;
