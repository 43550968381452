import React from "react";
import Header from "./Header";
import { Box } from "@chakra-ui/react";

const OtherHeader = () => {
  return (
    <Box
      display="none"
      sx={{
        "@media screen and (min-width: 768px)": {
          display: "block",
        },
      }}
    >
      <Header />
    </Box>
  );
};

export default OtherHeader;
