import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { showNotification } from "@mantine/notifications";
import {useState} from "react"

const useViewAnalytics = () => {
  const user = useSelector((state: RootState) => state.user);

// const [loading]
  const viewAnalytics = async (values: FormikValues) => {

    return await axiosBaseInstance
      .post("/WassapviewLinks/ViewAnalysisSetup", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        console.log(res)
        showNotification({
            message: res.data.data,
            color: "green",
          });
        return res;
      })
      .then((err) => {
        return err;
      });
  };

  return { viewAnalytics };
};

export default useViewAnalytics;
