import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";

const useFetchTransactionDetails = () => {
  const user = useSelector((state: RootState) => state.user);

  const fetchDetails = async (id: string) => {
    return await axiosBaseInstance
      .get(`/Wallet/Transactions-Details`, {
        params: {
          Id: id,
        },
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        return res.data?.data;
      })
      .catch((err) => {
        return err;
      });
  };

  return { fetchDetails };
};

export default useFetchTransactionDetails;
