import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { reviewInterface } from "../../types/components/componentInterfaces";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
const Review = ({ picture, name, review, createdDate }: reviewInterface) => {
  return (
    <div>
      <Box
        border="1px solid #D3D3D3"
        p={[3, "10px 20px 20px"]}
        mb="10px"
        borderRadius="10px"
      >
        <Flex align="center" mb="5px">
          <Avatar name="Devups" src={picture} width="38px" height="38px" />
          <Box ml="10px">
            <Text fontSize="14px" fontWeight="500">
              {name}
            </Text>
            <Text color="grey.dark" fontSize="12px">
              {dayjs(createdDate).fromNow()}
            </Text>
          </Box>
        </Flex>
        <Text fontSize="14px" fontWeight="500" color="#323232">
          {review}
        </Text>
      </Box>
    </div>
  );
};

export default Review;
