import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";

const useFetchBusinessTypes = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchBusinessTypes = async () => {
    return await axiosBaseInstance
      .get("/MyProfile/BusinessTypes")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  return { isLoading, fetchBusinessTypes };
};

export default useFetchBusinessTypes;
