import { OtherHeader, PageHeader } from "../../../components";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ProfileItem from "./components/ProfileItem";
import { useEffect, useState } from "react";
import useFetchUserProfile from "../../../hooks/user-hooks/useFetchUserProfile";
import dayjs from "dayjs";
import useFetchBusinessTypes from "../../../hooks/data-hooks/useFetchBusinessTypes";
import { Link } from "react-router-dom";

const Profile = () => {
  const [businessType, setBusinessType] = useState("");
  const { fetchProfile } = useFetchUserProfile();
  const { fetchBusinessTypes } = useFetchBusinessTypes();
  const user = useSelector((state: RootState) => state.user);

  const userDetils = [
    { title: "Email Address", value: user?.data.email },
    {
      title: "Date of Birth",
      value: dayjs(user?.data.dob).format("DD MMMM YYYY"),
    },
    { title: "Gender", value: user?.data.gender },
    { title: "Phone Number", value: user?.data.phoneNumber },
    { title: "Address", value: user?.data.address },
    { title: "Type of Business", value: businessType },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResponse = await fetchProfile();
        const profileData = profileResponse.data.data;

        const businessTypesResponse = await fetchBusinessTypes();
        const businessTypesData = businessTypesResponse.data.data;

        const selectedBusinessType = businessTypesData.find(
          (type: any) => type.id === profileData.businessId
        );

        setBusinessType(selectedBusinessType.name);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <OtherHeader />

      <PageHeader link="/dashboard/more" text="Profile" />
      <Box mb="20px" p="16px" mt="10px" bgColor="#FAFAFA" borderRadius="10px">
        <Flex justifyContent="space-between" marginBottom="20px">
          <Box borderRadius="100%" width="70px" height="70px" overflow="hidden">
            <Image
              src={user?.data.profileImage}
              alt={user?.data.fullName}
              objectFit="cover"
              width="100%"
              height="100%"
            />
          </Box>

          <Link to="/dashboard/edit-profile">
            <Text
              border="1px solid black"
              bgColor="transparent"
              borderRadius="20px"
              height="fit-content"
              padding="4px 12px"
            >
              Edit Profile
            </Text>
          </Link>
        </Flex>

        <Box>
          <Text fontSize="34px" fontWeight="500" textTransform="capitalize">
            {user?.data.fullName}
          </Text>
          <Text color="grey.dark">
            {`member since ${dayjs(user?.data.createdDate).format(
              "MMMM YYYY"
            )}`}
          </Text>
        </Box>
      </Box>
      <Box marginBottom="30px">
        {userDetils?.map(({ title, value }) => (
          <ProfileItem title={title} value={value ? value : ""} key={title} />
        ))}

        {/* <ProfileItem title="Type of Business" value={businessType} /> */}
      </Box>
    </>
  );
};

export default Profile;
