import styles from "./onboardingslide.module.scss";
import "./onboardingslide.module.scss";
import { onbaordingSlideInterface } from "../../types/components/componentInterfaces";
import { Image } from "@chakra-ui/react";

const OnboardingSlide = ({
  icon,
  title,
  description,
}: onbaordingSlideInterface) => {
  return (
    <div className={styles[`slide`]}>
      <Image src={icon} alt="" />

      <h2>{title}</h2>
      <p className="px-10 text-center">{description}</p>
    </div>
  );
};

export default OnboardingSlide;
