import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SlArrowLeft } from "react-icons/sl";
import { Link, useParams } from "react-router-dom";
import { Header, OtherHeader, PageHeader } from "../../../components";
import useFetchTransactionDetails from "../../../hooks/wallet-hooks/useFetchTransactionDetails";
import { Rating, Loader } from "@mantine/core";
import { transactionInterface } from "../../../types/components/componentInterfaces";
import dayjs from "dayjs";
import TransactionRow from "./components/TransactionRow";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";

const TransactionDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<transactionInterface>();
  const { fetchDetails } = useFetchTransactionDetails();
  const { transactionId } = useParams();
  const wallet = useSelector((state: RootState) => state.wallet);

  const handleTransactionDetails = () => {
    if (transactionId) {
      fetchDetails(transactionId).then((data) => {
        setData(data);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleTransactionDetails();
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader style={{ margin: "100px auto" }} size="lg" color="#4cb051" />
      ) : (
        <>
          <OtherHeader />

          <PageHeader
            link="/dashboard/wallet/transaction-history"
            text="Details"
          />

          <Box>
            <Flex
              marginBottom="30px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Text
                  fontSize="35px"
                  fontWeight="700"
                >{`N${data?.amount.toLocaleString()}`}</Text>
                <Text fontWeight="600" color="grey.dark">{`${dayjs(
                  data?.createdDate
                ).format("DD MMM, YYYY")} at ${dayjs(data?.createdDate).format(
                  "hh:mmA"
                )}`}</Text>
              </Box>

              <Link to="/dashboard/wallet/top-up">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  width="50px"
                  height="50px"
                  borderRadius="100%"
                  backgroundColor="green.150"
                >
                  <AiOutlinePlus
                    style={{ color: "var(--chakra-colors-green-100" }}
                    fontSize="20px"
                  />
                </Flex>
              </Link>
            </Flex>

            <Box>
              <TransactionRow title="From" children={data?.fundBy} />
              <TransactionRow title="Recipient" children={data?.fundChannel} />
              <TransactionRow
                title="Status"
                children={
                  <Text
                    color={
                      data?.status?.toLowerCase() === "initiated"
                        ? "#FBBB00"
                        : data?.status?.toLowerCase() === "success"
                        ? "#34C759"
                        : "#F14336"
                    }
                  >
                    {data?.status?.toLowerCase() === "initiated"
                      ? "Pending"
                      : data?.status?.toLowerCase() === "success"
                      ? "Successful"
                      : "Declined"}
                  </Text>
                }
              />
              <TransactionRow
                title="New balance"
                children={
                  <Text fontWeight="700" color="black.main" fontSize="18px">
                    {`N${wallet.balance.toLocaleString()}`}
                  </Text>
                }
              />
              <TransactionRow title="Session ID" children={data?.referenceId} />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default TransactionDetails;
