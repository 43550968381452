import { FormikValues } from "formik";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useSubmitPremium = () => {
  const user = useSelector((state: RootState) => state.user);

  const submitPremiumRequest = async (values: FormikValues) => {
    return await axiosBaseInstance
      .post("/WhatsAppVCF/Submit-Premium-Request", values, {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        return res;
      })
      .then((err) => {
        return err;
      });
  };
  return { submitPremiumRequest };
};

export default useSubmitPremium;
