import { Text } from "@chakra-ui/react";
import React from "react";
interface props {
  amount: number;
}

const Price = ({ amount }: props) => {
  return (
    <Text fontSize="16px" fontWeight="500">
      {`N${amount.toLocaleString()}`}
    </Text>
  );
};

export default Price;
