import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { authInterface } from "../../types/auth/authInterfaces";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

import { IconX } from "@tabler/icons-react";
import { Notification } from "@mantine/core";

export default function useSignup() {
  const [results, setResults] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const signup = async (data: authInterface) => {
    setLoadings(true);

    return await axiosBaseInstance
      .post("/Onboarding/SignUp", data)
      .then((res) => {
        setLoadings(false);

        if (res.data.Status === false) {
          return showNotification({
            message: res.data.Message || "an error occurred. Try again",
            color: "yellow",
          });
        } else {
          showNotification({
            title: "Success",
            message: "Signup successful",
            color: "green",
          });
          setResults(res.data);
          setIsSuccess(true);
          navigate("/confirm-email");

          return res.data;
        }
      })
      .catch((err) => {
        setLoadings(false);
        setErrors(err.message);
        setIsError(true);

        <Notification icon={<IconX size="1.1rem" />} color="red">
          Bummer! Notification without title
        </Notification>;

        return err;
      });
  };

  return { isSuccess, isError, loadings, results, errors, signup };
}
