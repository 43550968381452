import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";



interface searchBrand{
    brandItem:any
}
const UseFetchBrandAnalysis:any = (brandName:any) => {
  const user = useSelector((state: RootState) => state.user);
  const [fetching, setFectching] = useState(false)
//   const [fetching, setFectching] = useState(false)
  const [clicks, setClicks] = useState(false)
  const [data, setData] = useState<any>([])

  useEffect(()=>{
    const fetchAllLinksAnalysis = async () => {
        setFectching(true)
            return await axiosBaseInstance
              .get(`/WassapviewLinks/Brand/ClicksData/${brandName}`, {
               
                headers: {
                  Authorization: `Bearer ${user?.authToken}`,
                },
              })
              .then((res) => {
                if(res.data.status === true){
                    console.log(res.data)
                    setData(res.data)
                 setFectching(false)
                 setClicks(true)
                }else{
                 setClicks(false)
                 setFectching(false)
                }
         
               
                return res.data  
              }).catch((err:any)=>{
        
              });
          };
        fetchAllLinksAnalysis()
        
  }, [user?.authToken, brandName])

  return {fetching , data};
 
};

export default UseFetchBrandAnalysis;
