import { Button, BottomModal as ModalLayout } from "../../../../components";
import { Box, InputGroup, Text } from "@chakra-ui/react";
import CustomInput from "../../../../components/core/input/CustomInput";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import useSubmitContact from "../../../../hooks/vcf-hooks/useSubmitContact";
import { useState } from "react";
import ContactSuccessModal from "./ContactSuccessModal";
import { showNotification } from "@mantine/notifications";

import ShareModal from "../../../../components/Modals/ShareModal/ShareModal";
import { countryCodes } from "../../../../config/data";
import { useNavigate } from "react-router-dom";

interface props {
  hideModal: () => void;
}

const SubmitContactModal = ({ hideModal }: props) => {
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const { submitContact } = useSubmitContact();

  const navigate = useNavigate();
  const contactSchema = Yup.object().shape({
    countryCode: Yup.string().required("Please select a country code"),
    whatsAppNumber: Yup.string()
      .min(10)
      .max(11, "cannot add more than 11 digits")
      .required("Please enter a 11-digit number"),
    name: Yup.string().required("Please provide your name"),

  });

  const handleSubmit = (values: FormikValues) => {
    setIsLoading(true);
    submitContact(values)
      .then((res) => {
        setIsLoading(false);
        console.log(res)

        if (res?.data.data?.isContactSubmit === true) {
          setIsSuccess(true);
        } else {
          showNotification({
            message: res?.data?.Message,
            color: "yellow",
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        showNotification({
          message: err?.response?.statusText || err?.message,
          color: "yellow",
        });
      });
  };

  const handleShareModal = () => {
    setIsSuccess(false);
    setIsShareModal(true);
  };
  return (
    <>
      {isSuccess ? (
        <ContactSuccessModal
          onclose={() => {
            hideModal();
            navigate("/dashboard/download-free-contact");
          }}
          shareFunction={handleShareModal}
        />
      ) : !isSuccess && isShareModal ? (
        <ShareModal
          onclose={() => {
            hideModal();
            navigate("/dashboard/download-free-contact");
          }}
          link=""
        />
      ) : (
        <ModalLayout hideModal={hideModal}>
          <Text fontWeight="500" textAlign="center" marginBottom="10px">
            Submit Contact
          </Text>

          <Formik
            initialValues={{ whatsAppNumber: "", countryCode: "+234", name:"" }}
            validationSchema={contactSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  if (Object.keys(errors).length > 0) {
                    setIsValidatingSchema(true);
                  } else {
                    setIsValidatingSchema(false);
                    handleSubmit();
                  }
                }}
              >
                <InputGroup display="flex" flexDirection="row">
                  <div>
                    <select
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      style={{
                        // width: "30%",
                        marginRight: "8px",
                        borderRadius: "10px",
                        height: "55px",
                        backgroundColor: "#F1F1F1",
                        marginTop: "5px",
                      }}
                    >
                      {countryCodes?.map((code) => (
                        <option value={code}>{code}</option>
                      ))}
                    </select>
                    {errors.countryCode && (
                      <div className="error">{errors.countryCode}</div>
                    )}
                  </div>
                  <div>
<div 

style={{
  width: "250px",
  // marginRight: "8px",
  // borderRadius: "10px",
  // height: "55px",
  // backgroundColor: "red",
  // marginTop: "5px",
}}
>
<CustomInput
                    width="auto"
                    placeholder="Enter phone number"
                    name="whatsAppNumber"
                    value={values.whatsAppNumber}
                    onChange={handleChange}
                    error={
                      isValidatingSchema && errors.whatsAppNumber
                        ? errors.whatsAppNumber
                        : null
                    }
                  />
</div>
                
                  <div>
                  <CustomInput
                    width="auto"
                    placeholder="Enter Name Here"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={
                      isValidatingSchema && errors.name
                        ? errors.name
                        : null
                    }
                  />
                  </div>
                  </div>

             
                </InputGroup>

                <Text fontSize="14px" color="#717171" textAlign="center">
                  Submit your contact and be eligible to download free contacts.
                </Text>

                <Box marginBottom="20px" marginTop="30px">
                  <Button type="submit" variant="primary" size="normal">
                    {isLoading ? "Please wait..." : "Submit"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </ModalLayout>
      )}
    </>
  );
};

export default SubmitContactModal;
