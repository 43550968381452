import { Box, Text } from "@chakra-ui/react";

interface props {
  title: string;
  value?: string;
}

const ProfileItem = ({ title, value }: props) => {
  return (
    <Box
      borderBottom="0.5px solid #D9D9D9"
      mt="20px"
      pb="10px"
      marginBottom="10px"
    >
      <Text fontSize="16px" fontWeight="500" color="black.main">
        {title}
      </Text>
      <Text color="grey.dark" fontSize="16px">
        {value}
      </Text>
    </Box>
  );
};

export default ProfileItem;
