import { Box } from "@chakra-ui/react";
import { dashboardInterface } from "../../../types/components/componentInterfaces";
import { Outlet } from "react-router-dom";

const GeneralLayout = () => {
  return (
    <Box>
      <Box
        width="90%"
        margin="auto"
        paddingBottom="80px"
        sx={{
          "@media screen and (min-width: 768px)": {
            maxWidth: "640px",
            paddingTop: "20px",
            paddingLeft: "210px",
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default GeneralLayout;
