import { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Typography from "../../../components/core/typo/Typography";
import CustomInput from "../../../components/core/input/CustomInput";
import Button from "../../../components/core/Button/Button";
import { GeneralLayout as Layout } from "../../../components";
import { Link } from "react-router-dom";
import SocialAuth from "../components/SocialAuth";
import useLogin from "../../../hooks/auth-hooks/useLogin";

const SignIn = () => {
  const [formData, setFormData] = useState({ email: "", passWord: "" });

  const { login, loadings } = useLogin();

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await login({ email: formData.email, passWord: formData.passWord });
  };

  return (
    <>
      <Text
        fontSize="34px"
        fontWeight="500"
        color="black.main"
        marginTop="20px"
      >
        Lets sign you in
      </Text>

      <Box
        fontSize="14px"
        w="100%"
        marginBottom="20px"
        fontWeight="400"
        color="grey.main"
      >
        Welcome back, please login into your account
      </Box>
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        <Box w="100%" marginBottom="15px">
          <CustomInput
            placeholder="Enter your email address"
            label="Email"
            value={formData.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, email: e.target.value });
            }}
            required
          />
        </Box>

        <Box w="100%">
          <CustomInput
            placeholder="Enter your pasword"
            type="password"
            label="Password"
            value={formData.passWord}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, passWord: e.target.value });
            }}
            required
          />
        </Box>

        <Box w="100%">
          <Box>
            <Link to="/forget-password">
              <Typography color="#6DBAF3">Forget password?</Typography>
            </Link>
          </Box>
        </Box>
        <Box w="100%" marginTop={4}>
          <Button
            variant="primary"
            type="submit"
            size="normal"
            style={{ marginBottom: "12px" }}
          >
            {loadings ? "Please wait..." : "Login"}
          </Button>
        </Box>

        <SocialAuth />
      </form>

      <Flex justifyContent="center" marginBottom="20px">
        <Typography>Don't have an account?</Typography>

        <Link to="/register">
          <Typography marginLeft="5px" color="green">
            Register now
          </Typography>
        </Link>
      </Flex>
    </>
  );
};

export default SignIn;
