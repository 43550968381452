import { Flex, Text } from "@chakra-ui/react";
import { Button, BottomModal as Modal } from "../../../../components";
import { modalComponentInterface } from "../../../../types/components/componentInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { removeUser } from "../../../../redux/reducers/userReducer";
import { useNavigate } from "react-router-dom";

const ConfirmLogout = ({ onclick }: modalComponentInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    console.log("A");
    dispatch(removeUser());
    navigate("/signin");
  };
  return (
    <Modal hideModal={onclick}>
      <div>
        <Text
          fontSize="18px"
          fontWeight={700}
          marginBottom={4}
          textAlign="center"
        >
          Log out
        </Text>
        <Text
          fontSize={16}
          textColor="black.100"
          marginBottom="24px"
          textAlign="center"
        >
          Are you sure you want to log out?
        </Text>
      </div>

      <Flex
        justifyContent="space-around"
        alignItems="center"
        gap="5"
        marginBottom="10px"
      >
        <Button variant="primary" size="normal" type="button" onClick={logout}>
          Yes, log out
        </Button>
        <Button
          variant="secondary"
          size="normal"
          type="button"
          onClick={onclick}
        >
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
};

export default ConfirmLogout;
