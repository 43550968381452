import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";


const useChatUser = () => {
  const user = useSelector((state: RootState) => state.user);

// const [loading]
  const createFreeWHatsappChat = async (values: FormikValues) => {
    console.log(values)

    return await axiosBaseInstance
      .post("/WassapviewLinks/WhatsAppChat", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        console.log(res)
        return res.data;
      })
      .then((err) => {
        return err;
      });
  };

  return { createFreeWHatsappChat };
};

export default useChatUser;
