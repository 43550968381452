import { Button, MiddleModal } from "../..";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import copy from "clipboard-copy";
import facebookLogo from "../../../assets/svgs/facebook.svg";
import whatsappLogo from "../../../assets/svgs/whatsapp-logo.svg";
import twitterLogo from "../../../assets/svgs/twitter-logo.svg";
import { showNotification } from "@mantine/notifications";

interface props {
  onclose: () => void;
  link?: string;
}

const ShareModal = ({ onclose, link }: props) => {
  const clipboardText =
    "Have you been wondering the strategies your friends are using to boost their WhatsApp views??? \n\nLeave the wonder land, click the link below and find out the secret \nvisit 👉👇 \nhttps://www.wassapview.com/";

  const handleCopyLink = () => {
    copy(clipboardText);
    showNotification({
      message: "Link copied. Share with friends",
      color: "green",
    });
  };
  return (
    <MiddleModal onclose={onclose}>
      <Text fontSize="20px" fontWeight={600} marginBottom={5}>
        Share with friends
      </Text>

      <Box marginBottom="10px">
        <Button
          type="submit"
          variant="primary"
          size="normal"
          onClick={handleCopyLink}
        >
          Copy and share
        </Button>
      </Box>

      <Flex
        alignItems="center"
        justifyContent="center"
        gap="20px"
        minWidth="300px"
        marginBottom={4}
      >
        <div
          className="fb-share-button"
          data-href={process.env.LIVE_URL}
          data-layout=""
          data-size=""
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${clipboardText}/%2F&amp;src=sdkpreparse`}
            className="fb-xfbml-parse-ignore"
          >
            <Image src={facebookLogo} width="32px" />
          </a>
        </div>
        <a
          href={`https://api.whatsapp.com/send?text=${clipboardText}`}
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noreferrer"
        >
          <Image src={whatsappLogo} alt="whatsapp logo" width="36px" />
        </a>

        <a
          className="twitter-share-button"
          href={`https://twitter.com/intent/tweet?text=${clipboardText}`}
          target="_blank"
          rel="noreferrer"
        >
          <Image src={twitterLogo} alt="whatsapp logo" width="36px" />
        </a>
      </Flex>
    </MiddleModal>
  );
};

export default ShareModal;
