import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { vcfFileInterface } from "../../types/components/componentInterfaces";
// import useGetDownloadPremiumHistory from "./useGetDownloadPremiumHistory";

const useGetDownloadPremiumHistory = () => {
  const [data, setData] = useState<Array<vcfFileInterface>>([]);
  const user = useSelector((state: RootState) => state.user);

  const fetchPremiumHistory = async () => {
    try {
      await axiosBaseInstance
        .get("/WhatsAppVCF/Premium-History", {
          headers: {
            Authorization: `Bearer ${user.authToken}`,
          },
        })
        .then((res) => {
          if (res.data.Status === false) {
            return;
          }
          console.log(res);

          setData(res.data.data);
        });
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return { fetchPremiumHistory, data };
};

export default useGetDownloadPremiumHistory;
