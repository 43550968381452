import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";



interface searchBrand{
    brandItem:any
}
const useFetchwhatsappLinkDetail = (id:any) => {
  const user = useSelector((state: RootState) => state.user);
  const [fetching, setFectching] = useState(false)
  const [data, setData] = useState<any>(null)

  useEffect(()=>{
    const fetchSingleLinks = async () => {
        setFectching(true)
            return await axiosBaseInstance
              .get(`/WassapviewLinks/Link/Details/${id}`, {
               
                headers: {
                  Authorization: `Bearer ${user?.authToken}`,
                },
              })
              .then((res) => {
                console.log(res.data)
                setData(res.data.data)
             setFectching(false)
               
                return res.data
              }).catch((err:any)=>{
        
              });
          };
        fetchSingleLinks()
        
  }, [user?.authToken, id])

  return {fetching , data};
 
};

export default useFetchwhatsappLinkDetail;
