import { Box } from "@chakra-ui/react";
import {
  Header,
  DashboardLayout as Layout,
  MoreItem,
  OtherHeader,
  PageHeader,
} from "../../../components";
import { items } from "./utils/values";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useState } from "react";
import ConfirmLogout from "./components/ConfirmLogout";

const More = () => {
  const [isConfirmLogout, setIsConfirmLogout] = useState(false);

  const controlLogout = () => {
    setIsConfirmLogout(!isConfirmLogout);
  };

  return (
    <>
      <OtherHeader />

      {isConfirmLogout && <ConfirmLogout onclick={controlLogout} />}
      <PageHeader text="More" link="/dashboard" />
      {items?.map((item) => (
        <MoreItem key={item.name} {...item} />
      ))}
      <Box marginTop={10}>
        <MoreItem
          name="Log out"
          link=""
          color="rgba(255, 0, 0, 0.1)"
          Icon={RiLogoutCircleRLine}
          onclick={controlLogout}
        />
      </Box>
    </>
  );
};

export default More;
