import React from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useFetchMyWhatsappTv = () => {
  const user = useSelector((state: RootState) => state.user);
  // console.log(user.authToken);
  const fetchMyWhatsappTv = async () => {
    if (user.authToken === "") {
      return await axiosBaseInstance
        .get("/WhatsappTv/Share-MyWhatsapptv", {
          params: {
            TvId: 12,
          },
        })
        .then((res) => {
          // console.log(res);
          return res.data;
        });
    } else {
      return await axiosBaseInstance
        .get("/WhatsappTv/personalwhatsapptv", {
          headers: {
            Authorization: `Bearer ${user?.authToken}`,
          },
        })
        .then((res) => {
          console.log(res);
          return res.data;
        });
    }
  };

  return { fetchMyWhatsappTv };
};

export default useFetchMyWhatsappTv;
