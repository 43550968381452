import { Box, Image, Text } from "@chakra-ui/react";
import { cardInterface } from "../../types/components/componentInterfaces";
import { RxExternalLink } from "react-icons/rx";
import { Link } from "react-router-dom";

const ServiceCard = ({ icon, title, onClick, link }: cardInterface) => {
  return (
    <Link to={link} target="_blank">
      <Box
        borderRadius="10px"
        padding="8px"
        backgroundColor="#fff"
        gap="3px"
        filter="drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.1))"
        position="relative"
        cursor="pointer"
        onClick={onClick}
        sx={{
          "@media screen and (min-width: 768px)": {
            padding: "30px 50px",
            gap: "10px",
          },
        }}
      >
        <RxExternalLink
          fontSize="9px"
          color="#292D32"
          style={{ position: "absolute", top: "9px", right: "9px" }}
        />
        <Image
          src={icon}
          alt={title}
          style={{ margin: "auto" }}
          sx={{
            "@media screen and (min-width: 450px)": {
              width: "80px",
            },
          }}
        />
        <Text fontSize="10px" fontWeight="500" textAlign="center">
          {title}
        </Text>
      </Box>
    </Link>
  );
};

export default ServiceCard;
