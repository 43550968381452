import * as yup from "yup";

export const validationSchema = yup.object().shape({
  whatsAppTVName: yup.string().required("Please enter your TV name"),
  whatsAppNumber: yup
    .string()
    .min(11, "Enter a valid phone number")
    .required("Please enter your phone number"),
  whatsAppViewRange: yup.string().required("Please enter your phone number"),
  linkPrice: yup.number().required("add a price"),
  picturePrice: yup.number().required("add a price"),
  videoPrice: yup.number().required("add a price"),
  whatsAppTVProfilePicture: yup
    .string()
    .required("WhatsAppTV logo is required"),
  whatsAppDailyViewRangePicture: yup
    .string()
    .required("picture proof is required"),
});
