import { Box, Text } from "@chakra-ui/react";
// import { sortTypes } from "../utils/values";
import styles from "../tvs.module.scss";
import { BottomModal as ModalLayout } from "../../../../components";

interface props {
  hideModal: () => void;
  defaultFunc: () => void;
  hightestFunc: () => void;
  lowestFunc: () => void;
}
const SortModal = ({
  hideModal,
  defaultFunc,
  hightestFunc,
  lowestFunc,
}: props) => {
  const sortTypes = [
    { id: "default", value: "default", onclick: defaultFunc },
    { id: "highest-price", value: "highest price", onclick: hightestFunc },
    { id: "lowest-price", value: "lowest price", onclick: lowestFunc },
  ];
  const priceTypes = [
    { id: "linkPrice", name: "Link Price" },
    { id: "picturePrice", name: "Picture Price" },
    { id: "videoPrice", name: "Video Price" },
  ];

  return (
    <ModalLayout hideModal={hideModal}>
      <Text fontWeight="500" textAlign="center" marginBottom="20px">
        Sort by
      </Text>

      <select
        placeholder="Select option"
        style={{
          width: "100%",
          borderRadius: "20px",
          border: "0.5px solid #030202",
          padding: "8px",
          backgroundColor: "#fff",
          outlineColor: "transparent",
          marginBottom: "10px",
        }}
      >
        {priceTypes.map((priceType) => (
          <option key={priceType.id} value={priceType.id}>
            {priceType.name}
          </option>
        ))}
      </select>

      {sortTypes?.map(({ id, value, onclick }) => (
        <Box
          key={id}
          className={styles.radio_option}
          onClick={onclick}
          cursor="pointer"
        >
          <label
            htmlFor={id}
            style={{
              cursor: "pointer",
            }}
          >
            {value}
          </label>
          <input
            type="radio"
            name="sort"
            value={id}
            id={id}
            defaultChecked={id === "default" ? true : false}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
      ))}
    </ModalLayout>
  );
};

export default SortModal;
