import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";

export default function useResendCode() {
  const [results, setResults] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState("");

  const resendCode = async (idToken: string) => {
    setLoadings(true);

    return await axiosBaseInstance
      .post("/Onboarding/ResendCode", { idToken })
      .then((res) => {
        setResults(res.data);
        setLoadings(false);
console.log(res)
        showNotification({
          message: "New token resent",
          color: "green",
        });
      })
      .catch((error: any) => {
        setLoadings(false);
        setErrors(error.message);
        console.log(error);
        showNotification({
          message:
            error?.response?.data?.message || "An error occurred. Try again",
          color: "yellow",
        });
      });
  };

  return { loadings, results, errors, resendCode };
}
