import { Component, ErrorInfo, ReactNode } from "react";
import { BiError } from "react-icons/bi";
import { Button } from ".";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}
// import Button from "./Button";

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    // return { hasError: true };
    return { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen w-full bg-tree1 bg-cover bg-left-top  flex flex-col justify-top items-center pt-28">
          <BiError fontSize="80px" color="red" />
          <h1 className="font-extrabold">Something went wrong</h1>
          <p className="text-lg font-bold">
            It's not you but us, please hold on while we fix this
          </p>
          <Button
            type="button"
            className="bg-primary"
            style={{ width: "20%" }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload Page
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
