import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";
import { updateUser } from "../../redux/reducers/userReducer";
import useFetchBusinessTypes from "../data-hooks/useFetchBusinessTypes";

const useFetchUserProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { fetchBusinessTypes } = useFetchBusinessTypes();

  const fetchProfile = async () => {
    return await axiosBaseInstance
      .get("/MyProfile/GetProfile", {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === true) {
          // console.log(res.data.data);
          dispatch(updateUser(res.data.data));

          fetchBusinessTypes().then((res) => {
            const data = res.data.data.find(
              (e: any) => e.id === res?.data?.data.businessId
            );

            // setBusinessType(data.name);
          });
        }

        return res;
      })
      .catch((error: any) => {
        setIsLoading(false);
        setErrors(error.message);

        showNotification({
          title: "Error",
          message:
            error?.response?.data?.title ||
            error?.message ||
            "An error occurred. Try again",
          color: "red",
        });

        return error;
      });
  };

  return { isLoading, errors, fetchProfile };
};

export default useFetchUserProfile;
