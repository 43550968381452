import * as Yup from "yup";
export const contactType = [
  { name: "option 1", id: 1 },
  { name: "option 2", id: 2 },
  { name: "option 3", id: 3 },
];
export const genders = [
  { name: "Male", id: "Male" },
  { name: "Female", id: "Female" },
];

export const initialValues = {
  fromDate: "",
  toDate: "",
  contactType: 0,
  gender: " ",
  numberOfContact: 1,
};

export const validationSchema = Yup.object().shape({
  fromDate: Yup.date()
    .test("notFuture", "Date must not be in the future", function (value) {
      if (!value) {
        return false; // Treat undefined values as invalid
      }
      return value <= new Date();
    })
    .required("Select the start date"),
  toDate: Yup.date()
    .test("notFuture", "Date must not be in the future", function (value) {
      if (!value) {
        return false; // Treat undefined values as invalid
      }
      return value <= new Date();
    })
    .required("Select the end date"),
  contactType: Yup.number().required("Choose the contact type"),
  gender: Yup.string().required("Please select gender"),
  numberOfContact: Yup.number()
    .min(0, "Number of contacts should be at least 1")
    .max(5000, "Max of 5000")
    .required("Please enter the number of contact you want"),
});
