import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

import {
  NoReviewNotice,
  OtherHeader,
  PageHeader,
  Price,
  RatingModal,
  Review,
  Service,
} from "../../../components";

import { Rating, Loader } from "@mantine/core";

import Reviews from "../../../components/WhatsAppTv/Reviews";
import useFetchSingleWhatsAppTv from "../../../hooks/whatsapptv-hooks/useFetchSingleWhatsAppTv";
import {
  reviewInterface,
  whatsappTvInterface,
} from "../../../types/components/componentInterfaces";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useFetchTvReviews from "../../../hooks/whatsapptv-hooks/useFetchTvReviews";

const WhatsAppTv = () => {
  const [isReview, setIsReview] = useState(false);
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [showReviewPage, setShowReviewPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tvDetails, setTvDetails] = useState<whatsappTvInterface>(
    {} as whatsappTvInterface
  );
  const [reviews, setReviews] = useState<Array<reviewInterface>>([]);
  const { fetchSingleWhatsAppTv } = useFetchSingleWhatsAppTv();
  const { getReviews } = useFetchTvReviews();

  const hideReviewPage = () => {
    setShowReviewPage(false);
  };

  const controlRatingModal = () => {
    setIsRatingModal(!isRatingModal);
  };

  const { whatsappTvId } = useParams();
  const handleWhatsappTv = () => {
    if (whatsappTvId) {
      fetchSingleWhatsAppTv(whatsappTvId).then((data) => {
        setIsLoading(false);
        // console.log(data);

        setTvDetails(data.data);
      });
    }
  };

  useEffect(() => { 
    setIsLoading(true);
    handleWhatsappTv();
  }, []);

  useEffect(() => {
    if (tvDetails.id) {
      getReviews(tvDetails?.id).then((data) => {
        if (data.data.length > 0) {
          console.log(data);
          setReviews(data.data);
          setIsReview(true);
        }
      });
    }
  }, [tvDetails.id]);
  return (
    <>
      <OtherHeader />

      {isLoading ? (
        <Loader style={{ margin: "100px auto" }} size="lg" color="#4cb051" />
      ) : (
        <>
          {showReviewPage ? (
            <Reviews onClick={hideReviewPage} />
          ) : (
            <>
              {isRatingModal && (
                <RatingModal
                  closeModal={controlRatingModal}
                  whatsAppTvId={tvDetails?.id}
                />
              )}
              <PageHeader
                text={tvDetails.whatsAppTVName}
                link="/dashboard/whatsapp-tvs"
              />
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                mt="10px"
                borderColor="#979797"
                borderBottomWidth="0.2px"
                mb="10px"
              >
                <Image
                  src={tvDetails.whatsAppTVProfilePicture}
                  boxSize="100px"
                  borderRadius="8px"
                  objectFit="cover"
                />

                <Box mb="10px">
                  <Text fontSize="20px" fontWeight="500">
                    {tvDetails.whatsAppViewRange}
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="grey.dark"
                    mb="10px"
                  >
                    WhatsApp Daily Views
                  </Text>

                  <Link
                    to={`https://wa.me/${tvDetails.countryCode}${tvDetails.whatsAppNumber}`}
                    target="_blank"
                  >
                    <Text
                      color="green.100"
                      fontSize="16px"
                      fontWeight="400"
                      display="flex"
                      alignItems="center"
                      mb="10px"
                    >
                      <span style={{ marginRight: "5px" }}>Message</span>{" "}
                      <FaWhatsapp />
                    </Text>
                  </Link>

                  <Flex alignItems="center">
                    <Text color="#000" marginRight="5px ">
                      {" "}
                      4.5{" "}
                    </Text>
                    <Rating
                      value={4.5}
                      fractions={2}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsRatingModal(true);
                      }}
                    />
                  </Flex>
                </Box>
              </Box>
              <Box>
                <Text
                  fontSize="16px"
                  fontWeight="500"
                  mt="20px"
                  mb="20px"
                  color="black.main"
                >
                  Pricing
                  <span
                    style={{
                      color: "var(-chakra-colors-green-100)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    /post
                  </span>
                </Text>

                <Flex align="center" justify="space-between" w="95%">
                  <Box textAlign="center">
                    <Service name="Link" />
                    <Price
                      amount={tvDetails.linkPrice ? tvDetails.linkPrice : 0}
                    />
                  </Box>
                  <span
                    style={{
                      height: "40px",
                      width: "2px",
                      backgroundColor: "#a5a5a5",
                    }}
                  ></span>
                  <Box textAlign="center">
                    <Service name="Status" />
                    <Price
                      amount={
                        tvDetails.picturePrice ? tvDetails?.picturePrice : 0
                      }
                    />
                  </Box>
                  <span
                    style={{
                      height: "40px",
                      width: "2px",
                      backgroundColor: "#a5a5a5",
                    }}
                  ></span>
                  <Box textAlign="center">
                    <Service name="Video" />
                    <Price
                      amount={tvDetails?.videoPrice ? tvDetails.videoPrice : 0}
                    />
                  </Box>
                </Flex>
              </Box>

              <Box>
                {isReview ? (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt="20px"
                      mb="20px"
                    >
                      <Text fontSize="16px" fontWeight="500">
                        Reviews
                      </Text>
                      <Link to="reviews">
                        <span
                          style={{
                            color: "var(--chakra-colors-green-100)",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          See All
                        </span>
                      </Link>
                    </Box>

                    <Box>
                      {reviews?.map((review) => (
                        <Review
                          picture={review.picture}
                          name={review.name}
                          review={review.review}
                          createdDate={`${review.createdDate}`}
                        />
                      ))}
                    </Box>
                  </>
                ) : (
                  <NoReviewNotice />
                )}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default WhatsAppTv;
