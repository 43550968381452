import { Text } from "@chakra-ui/react";
interface props {
  text: string;
}
const NoTransactionNotice = ({ text }: props) => {
  return (
    <Text
      color="grey.main"
      textAlign="center"
      fontSize="18px"
      fontWeight="500"
      marginTop="50px"
    >
      {text}
    </Text>
  );
};

export default NoTransactionNotice;
