import { Box, Flex } from "@chakra-ui/react";
import styles from "./header.module.scss";
import "./header.module.scss";
import logo from "../../assets/svgs/logo.svg";
import { NavLink } from "react-router-dom";
import { BsSend } from "react-icons/bs";
import { CgHome, CgMoreR } from "react-icons/cg";
import { IoWalletOutline } from "react-icons/io5";
import { useState } from "react";
import { ComingSoonModal } from "../../components";
// import { MdArrowForwardIos } from "react-icons/md";
// import { BsSend } from "react-icons/fi";
const Header = () => {
  const [isComingSoon, setIsComingSoon] = useState(false);

  const controlComingSoonModal = () => {
    setIsComingSoon(!isComingSoon);
  };

  const routes = [
    { title: "Home", Icon: CgHome, link: "/dashboard" },
    { title: "Wallet", Icon: IoWalletOutline, link: "/dashboard/wallet" },
    {
      title: "Ads",
      Icon: BsSend,
      link: "#",
      onclick: controlComingSoonModal,
    },
    { title: "More", Icon: CgMoreR, link: "/dashboard/more" },
  ];

  return (
    <>
      {isComingSoon && <ComingSoonModal onclick={controlComingSoonModal} />}

      <header>
        <Flex
          justifyContent="space-around"
          width="full"
          sx={{
            "@media screen and (min-width: 768px)": {
              maxWidth: "640px",
              justifyContent: "space-between",
              flexDirection: "column",
            },
          }}
        >
          <Flex
            marginBottom="20px"
            display="none"
            sx={{
              "@media screen and (min-width: 768px)": {
                display: "flex",
              },
            }}
          >
            <img
              src={logo}
              alt="wassapview"
              style={{ width: "23px", marginRight: "10px" }}
            />
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Wassapview
            </div>
          </Flex>
          {routes?.map(({ title, Icon, link, onclick }) =>
            title.toLowerCase() === "ads" ? (
              <Flex
                color="#676666"
                onClick={onclick}
                key={title}
                className={styles["nav-item"]}
              >
                <Icon className={styles["nav-icon"]} />
                <span>{title}</span>
                {/* <MdArrowForwardIos className={styles["arrow"]} /> */}
              </Flex>
            ) : (
              <NavLink
                end
                to={link}
                key={title}
                style={({ isActive }) => ({
                  color: isActive ? "#4CB051" : "#676666",
                })}
              >
                <Flex color="inherit" className={styles["nav-item"]}>
                  <Icon className={styles["nav-icon"]} />
                  <span>{title}</span>
                  {/* <MdArrowForwardIos className={styles["arrow"]} /> */}
                </Flex>
              </NavLink>
            )
          )}
        </Flex>
      </header>
    </>
  );
};

export default Header;
