import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { transactionInterface } from "../../types/components/componentInterfaces";

export const initialState: Array<transactionInterface> = [];

const transactionsReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateTransactions: (
      state = initialState,
      action: PayloadAction<Array<transactionInterface>>
    ) => {
      return (state = action.payload);
    },
  },
});

export const { updateTransactions } = transactionsReducer.actions;
export default transactionsReducer.reducer;
