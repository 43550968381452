import { Flex, Text } from "@chakra-ui/react";

import { SlArrowLeft } from "react-icons/sl";
import { Link } from "react-router-dom";

interface props {
  text: string;
  link: string;
  onClick?: () => void;
  rightIcon?: JSX.Element;
}
const PageHeader = ({ link, text, rightIcon, onClick }: props) => {
  return (
    <Flex alignItems="center" padding="20px 0" width="full">
      <Link to={link} onClick={onClick}>
        <SlArrowLeft />
      </Link>

      <Text textAlign="center" fontSize="20px" fontWeight="700" margin="auto">
        {text}
      </Text>

      {rightIcon && <span>{rightIcon}</span>}
    </Flex>
  );
};

export default PageHeader;
