import dayjs from "dayjs";
import * as yup from "yup";

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email address")
    .required("Email address is required"),
});
export const resetPasswordSchema = yup.object().shape({
  resetOTP: yup
    .string()
    .trim("value cannot contain spaces")
    .required("Please enter OTP sent to your email"),
  newPassWord: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#.,$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character"
    )
    .required("password is required"),

  confirmPassword: yup
    .string()
    .required("Please re-enter your password.")
    .oneOf([yup.ref("newPassWord")], "passwords must match"),
});

export const profileSchema = yup.object().shape({
  profileImage: yup.lazy((value) =>
    /^data/.test(value)
      ? yup
          .string()
          .trim()
          .matches(
            /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
            "Must be a valid data URI"
          )
          .required()
      : yup
          .string()
          .trim()
          .url("Must be a valid URL")
          .required("Upload a profile photo")
  ),
  fullName: yup.string().required("Enter your full name"),
  address: yup.string().required("Enter a valid address"),
  phoneNumber: yup.string().required("Enter a valid phone number"),
  email: yup.string().email("Enter a valid email"),
  dob: yup
    .string()
    .required("Please add your date of birth")
    .test("DOB", "You must be at least 18 years old", (value) => {
      return dayjs().diff(dayjs(value), "year") >= 18;
    }),
  stateId: yup.number().required("Please select your state"),
  businessId: yup.number().required("Please select your type of business"),
  gender: yup.string().required("Select your gender"),
  facebookLink: yup.string().required("Enter your Facebook profile link"),
  instagramLink: yup.string().required("Enter your Instagram profile link"),
});
