import { Box, Image, Text } from "@chakra-ui/react";

import { whatsappTvInterface } from "../../types/components/componentInterfaces";
import { Rating } from "@mantine/core";
import { Link } from "react-router-dom";

const WhatsappTvCard = ({
  whatsAppTVName,
  whatsAppTVProfilePicture,
  id,
  whatsAppViewRange,
}: whatsappTvInterface) => {
  return (
    <Link to={`/dashboard/whatsapp-tvs/${id}`}>
      <Box
        display="flex"
        alignItems="center"
        mt="10px"
        mb="30px"
        color="black.main"
      >
        <Image
          src={whatsAppTVProfilePicture}
          boxSize="50px"
          borderRadius="5px"
          mr="10px"
          objectFit="cover"
        />

        <Box>
          {/* <Link to={`/dashboard/whatsapp-tvs/${id}`}> */}
          <Text fontSize="14px">{whatsAppTVName}</Text>
          {/* </Link> */}
          <Text fontSize="14px" fontWeight="500" mt="5px" mb="5px">
            {}
          </Text>
          <Text color="#5fde6e" fontSize="13px">
            {whatsAppViewRange}
          </Text>
        </Box>
        <Box marginLeft="auto" height="full">
          <Rating value={4.5} fractions={2} readOnly />

          <Text fontSize="12px" color="grey.dark" marginTop="15px">
            {} Reviews
          </Text>
        </Box>
      </Box>
    </Link>
  );
};

export default WhatsappTvCard;
