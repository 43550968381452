export const tvIntitialValues = {
  whatsAppTVName: "",
  whatsAppNumber: "",
  whatsAppViewRange: "",
  linkPrice: 0,
  picturePrice: 0,
  videoPrice: 0,
  whatsAppTVProfilePicture: "",
  whatsAppDailyViewRangePicture: "",
  countryCode: "",
};
