import { useEffect, useState } from "react";
import {
  Button,
  DownloadHistoryFile,
  NoTransactionNotice,
  OtherHeader,
  PageHeader,
  BottomModal as Modal,
} from "../../../components";
import useGetDownloadHistory from "../../../hooks/vcf-hooks/useGetDownloadHistory";
import { Box, Flex, Text } from "@chakra-ui/react";
import useDownload from "../../../hooks/vcf-hooks/useDownload";
import { useNavigate } from "react-router-dom";
import useFreeFiles from "../../../hooks/vcf-hooks/useFreeFiles";
import useGetDownloadPremiumHistory from "../../../hooks/vcf-hooks/useGetHistoryDownload";

const GetPremiumHistory = () => {
  const { fetchPremiumHistory, data } = useGetDownloadPremiumHistory();

  const navigate = useNavigate();
  const { download, result } = useDownload();
  const [loadingMap, setLoadingMap] = useState<{ [key: string]: boolean }>({});
  const [success, setSuccess] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [results, setResults] = useState<any>([]);
  

  const handleDownload = async (fileId: string) => {
    setIsDownloading(true);

    const response = await download({ fileId });
    if (response) {
      setSuccess(true);
      console.log(response, result);
      setResults(response.data.data);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [fileId]: false,
      }));
    } else {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [fileId]: false,
      }));
    }

    setIsDownloading(false);
  };
  const modalClick = () => {
    setIsModal(!isModal);
  };

  const Save = () => {
    const downloadUrl = result?.downloadUrl;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "whatsappVCF");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const close = () => {
    setSuccess(false);
    // navigate("/dashboard/download-free-contact");
  };
  useEffect(() => {
    fetchPremiumHistory();
  }, []);
  return (
    <>
      <OtherHeader />
      <PageHeader link="/dashboard" text="Download History" />

      <Box>
        {data?.length === 0 ? (
          <NoTransactionNotice text="Download file history not available" />
        ) : (
          data?.map(
            ({
              fileId,
              fileName,
              status,
              compiledDate,
              isDownloadAvailable,
            }) => (
              <DownloadHistoryFile
                fileName={fileName}
                fileId={fileId}
                status={status}
                isDownloadAvailable={isDownloadAvailable}
                compiledDate={compiledDate}
                isDownloading={fileId ? isDownloading : false}
                downloadFunc={() => {
                  handleDownload(`${fileId}`);
                }}
              />
            )
          )
        )}
        {success && (
          <Modal hideModal={modalClick}>
            <Box>
              <Text
                fontSize="18px"
                fontWeight={700}
                marginBottom={4}
                textAlign="center"
              >
                Download
              </Text>
              <Text
                fontSize={16}
                textColor="black.100"
                marginBottom="24px"
                textAlign="center"
              >
                Download processed, click to save VCF file
              </Text>
            </Box>

            <Flex
              justifyContent="space-around"
              alignItems="center"
              gap="5"
              marginBottom="10px"
            >
              <Button
                variant="primary"
                size="normal"
                type="button"
                onClick={Save}
              >
                Save
              </Button>
              <Button
                variant="secondary"
                size="normal"
                type="button"
                onClick={close}
              >
                Cancel
              </Button>
            </Flex>
          </Modal>
        )}
      </Box>
    </>
  );
};

export default GetPremiumHistory;
