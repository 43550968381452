import { useEffect } from "react";
import logo from "../../assets/svgs/logo.svg";
import styles from "./splashscreen.module.scss";
import { useNavigate } from "react-router-dom";

const SplashScreen = () => {
  const navigate = useNavigate();
  const handleVisit = () => {
    const value = localStorage.getItem("hasVisited");

    if (value === null) {
      setTimeout(() => {
        navigate("/onboarding");
      }, 3000);
    } else if (value === "true") {
      setTimeout(() => {
        navigate("/signin");
      }, 3000);
    }
  };

  useEffect(() => {
    handleVisit();
  }, []);
  return (
    <div className={styles[`splash_screen`]}>
      <img src={logo} alt="wassapview" />
    </div>
  );
};

export default SplashScreen;
