import { useEffect, useState } from "react";
import { Box, Text, InputGroup, Input } from "@chakra-ui/react";
import { BsFilter } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import "./tvs.module.scss";
import {
  NoTransactionNotice,
  OtherHeader,
  PageHeader,
  WhatsappTvCard,
} from "../../../components";

import SortModal from "./components/SortModal";
import { whatsappTvInterface } from "../../../types/components/componentInterfaces";
import useFetchAvailableTvs from "../../../hooks/whatsapptv-hooks/useFetchAvailableTvs";
import { Loader } from "@mantine/core";

const Available = () => {
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [availableTvs, setAvailableTvs] = useState<Array<whatsappTvInterface>>(
    []
  );
  const [params, setParams] = useState({
    name: "",
  });

  const { fetchAvailableTvs } = useFetchAvailableTvs(params);
  const showModal = () => {
    setIsModal(true);
  };

  const hideModal = () => {
    setIsModal(false);
  };

  const handleSearchChange = (value: string) => {
    setParams({ ...params, name: value });
  };

  const handleAvailableTvs = () => {
    setIsLoading(true);
    fetchAvailableTvs().then((data) => {
      setIsLoading(false);

      if (data.data !== undefined) {
        setAvailableTvs(data.data);
      }
    });
  };

  const filterHighestValue = () => {
    hideModal();

    const result = availableTvs.sort(
      (a, b) => parseInt(b.whatsAppViewRange) - parseInt(a.whatsAppViewRange)
    );
    setAvailableTvs(result);
  };
  const filterLowestValue = () => {
    hideModal();
    const result = availableTvs
      .sort(
        (a, b) => parseInt(b.whatsAppViewRange) - parseInt(a.whatsAppViewRange)
      )
      .reverse();
    setAvailableTvs(result);
  };

  useEffect(() => {
    handleAvailableTvs();
  }, [params]);

  useEffect(() => {
    handleAvailableTvs();
  }, []);
  return (
    <>
      <OtherHeader />
      <PageHeader link="/dashboard" text="WhatsApp Tv's" />

      <InputGroup
        bg="#F6F7FC"
        borderRadius="20px"
        h="50px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="0 12px"
      >
        <CiSearch size="24px" style={{ marginRight: "10px" }} />
        <Input
          placeholder="Search for a whatsapp TV"
          size="md"
          type="text"
          w="90%"
          outline="none"
          border="none"
          backgroundColor="transparent"
          variant="unstyled"
          p={[0, 2]}
          onChange={(e: any) => {
            handleSearchChange(e.target.value);
          }}
        />
      </InputGroup>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="10px"
        mb="10px"
      >
        <Text color="black.main">Available WhatsApp Tv</Text>
        <BsFilter size="24px" onClick={showModal} cursor="pointer" />
      </Box>

      <Box overflowY="auto" h="80vh">
        {isLoading ? (
          <Loader style={{ margin: "100px auto" }} size="lg" color="#4cb051" />
        ) : (
          <>
            {availableTvs.length === 0 ? (
              <NoTransactionNotice text="No Available TV" />
            ) : (
              availableTvs?.map((tv) => (
                <WhatsappTvCard
                  key={tv.id}
                  id={tv.id}
                  whatsAppTVName={tv.whatsAppTVName}
                  whatsAppDailyViewRangePicture={
                    tv.whatsAppDailyViewRangePicture
                  }
                  whatsAppTVProfilePicture={tv.whatsAppTVProfilePicture}
                  whatsAppViewRange={tv.whatsAppViewRange}
                />
              ))
            )}
          </>
        )}
      </Box>

      {isModal ? (
        <SortModal
          hideModal={hideModal}
          defaultFunc={handleAvailableTvs}
          hightestFunc={filterHighestValue}
          lowestFunc={filterLowestValue}
        />
      ) : null}
    </>
  );
};

export default Available;
