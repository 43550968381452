import { FormikValues } from "formik";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useSavePremium = () => {
  const user = useSelector((state: RootState) => state.user);

  const savePremiumRequest = async (values: FormikValues) => {
    const formvalues = {
      ...values,
      contactType: parseInt(values.contactType),
      numberOfContact: parseInt(values.numberOfContact),
    };

    return await axiosBaseInstance
      .post("/WhatsAppVCF/Save-Premium-Request", formvalues, {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        return res;
      })
      .then((err) => {
        return err;
      });
  };

  return { savePremiumRequest };
};

export default useSavePremium;
