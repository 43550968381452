import { Flex, Text } from "@chakra-ui/react";
import {
  DownloadContactTemp,
  OtherHeader,
  PageHeader,
} from "../../../components";
import { useState } from "react";
import { Link } from "react-router-dom";

const DownloadFreeContact = () => {
  const [isConfirmLogout, setIsConfirmLogout] = useState(false);
  const controlLogout = () => {
    setIsConfirmLogout(!isConfirmLogout);
  };
  return (
    <>
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader text="Download Free Contact" link="/dashboard" />

        <Link to="/dashboard/download-history">
          {/* <Box borderRadius="100%" backgroundColor="green.80" padding="5px"> */}
          {/* <RiHistoryFill
              fontSize="20px"
             
            /> */}
          <Text
            fontSize="11px"
            wordBreak="initial"
            width="max-content"
            color="green.100"
          >
            View history
          </Text>
          {/* </Box> */}
        </Link>
      </Flex>

      <DownloadContactTemp onclick={controlLogout} />
    </>
  );
};

export default DownloadFreeContact;
