import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const StateData = () => {
  const user = useSelector((state: RootState) => state.user);

  return { user };
};

export const profileInitialValues = {
  profileImage: "",
  fullName: "",
  address: "",
  phoneNumber: "",
  countryCode: "",
  email: "",
  dob: "",
  stateId: 0,
  businessId: 0,
  gender: "",
  facebookLink: "",
  instagramLink: "",
  createdDate: new Date(),
};

export const genders = [
  { name: "Male", id: "Male" },
  { name: "Female", id: "Female" },
];
