import { Header } from "../../";
import { dashboardInterface } from "../../../types/components/componentInterfaces";
import { Box } from "@chakra-ui/react";

const DashboardLayout = ({ children }: dashboardInterface) => {
  return (
    <Box>
      {/* <Header /> */}
      <Box
        paddingBottom="80px"
        width="90%"
        margin="auto"
        sx={{
          "@media screen and (min-width: 768px)": {
            width: "calc(100% - 142px)",
            paddingTop: "20px",
            paddingLeft: "210px",

            // paddingRight: "30px",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
