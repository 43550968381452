import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { priceBoxInterface } from "../../types/components/componentInterfaces";

const PriceBox = ({
  label,
  value,
  name,
  onChange,
  error,
}: priceBoxInterface) => {
  return (
    <>
      <Box textAlign="center">
        <Text className="input-label">{label}</Text>
        <Flex
          padding="20px"
          width="85px"
          textAlign="center"
          backgroundColor="#F1F1F1"
          borderRadius={10}
          outlineColor="green.100"
        >
          N
          <Input
            type="number"
            placeholder="0.00"
            backgroundColor="transparent"
            width="full"
            outline="0px"
            name={name}
            value={value}
            onChange={onChange}
          />
        </Flex>
        {error && <div className="error">{error}</div>}
      </Box>
    </>
  );
};

export default PriceBox;
