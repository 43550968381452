import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { useState } from "react";

const useCreateReview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const user = useSelector((state: RootState) => state.user);

  const addReview = async (values: FormikValues) => {
    setIsLoading(true);
    return await axiosBaseInstance
      .post("/WhatsappTv/createwhatsappreview", values, {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        setIsSuccess(true);
        return res.data;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { addReview, isLoading, isSuccess };
};

export default useCreateReview;
