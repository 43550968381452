import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";

const useFetchSingleWhatsAppTv = () => {
  const user = useSelector((state: RootState) => state.user);

  const fetchSingleWhatsAppTv = async (whatsAppTvId: string) => {
    return await axiosBaseInstance
      .get("/WhatsappTv/Get-WhatsAppTv", {
        params: {
          id: whatsAppTvId,
        },
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  return { fetchSingleWhatsAppTv };
};

export default useFetchSingleWhatsAppTv;
