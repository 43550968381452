import { PageHeader } from "..";
interface props {
  onClick: () => void;
}
const Reviews = ({ onClick }: props) => {
  return (
    <>
      <PageHeader
        link="/dashboard/whatsapp-tvs/1"
        text="Reviews"
        onClick={onClick}
      />
    </>
  );
};

export default Reviews;
