import { Box, Flex, Text } from "@chakra-ui/react";
import Typography from "../../../components/core/typo/Typography";
import CustomInput from "../../../components/core/input/CustomInput";
import Button from "../../../components/core/Button/Button";
import SocialAuth from "../components/SocialAuth";
import { Link } from "react-router-dom";
import { GeneralLayout as Layout } from "../../../components";
import useSignup from "../../../hooks/auth-hooks/useSignup";
import { validationSchema } from "./utils/validationSchema";
import { Formik, FormikValues } from "formik";
import { signupInitialValues } from "./utils/values";
import { useState } from "react";

const Register = () => {
  const { loadings, signup } = useSignup();
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);

  const onSubmitHandler = async (values: FormikValues) => {
    await signup({
      email: values.email,
      passWord: values.passWord,
      phoneNumber: values.phoneNumber,
    }).then((res) => {
      localStorage.setItem("idToken", res?.data?.idToken);
      localStorage.setItem("email", values.email);
    });
  };

  return (
    <>
      <Text w="100%" fontSize="34px" fontWeight="500" marginTop="10px">
        Lets sign you up
      </Text>
      <Box
        fontSize="14px"
        w="100%"
        marginBottom="20px"
        fontWeight="400"
        color="grey.main"
      >
        Create an account and enjoy amazing benefits
      </Box>
      <Formik
        initialValues={signupInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmitHandler(values);
        }}
      >
        {({ handleChange, handleSubmit,  errors, values }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (Object.keys(errors).length > 0) {
                setIsValidatingSchema(true);
              } else {
                setIsValidatingSchema(false);
                handleSubmit();
              }
            }}
          >
            <CustomInput
              placeholder="Enter your email address"
              id="email"
              label="Email"
              required
              name="email"
              value={values.email}
              onChange={handleChange}
              error={isValidatingSchema && errors.email ? errors.email : null}
            />
            {/* @ts-ignore */}

            <CustomInput
              placeholder="Enter your phone number"
              name="phoneNumber"
              id="phoneNumber"
              label="Phone number"
              value={values.phoneNumber}
              onChange={handleChange}
              error={
                isValidatingSchema && errors.phoneNumber
                  ? errors.phoneNumber
                  : null
              }
            />

            <CustomInput
              placeholder="Enter your password"
              type="password"
              name="passWord"
              label="Password"
              id="password"
              required
              value={values.passWord}
              onChange={handleChange}
              error={
                isValidatingSchema && errors.passWord ? errors.passWord : null
              }
            />

            <Button

              variant="primary"
              type="submit"
              size="normal"
              style={{ marginBottom: "12px" }}
            >
              {loadings ? "Please wait..." : "Register"}
            </Button>
       

         

            <SocialAuth />

            <Flex justifyContent="center" w="100%" marginBottom="20px">
              <Typography>Already have an account?</Typography>

              <Link to="/signin">
                <Typography marginLeft="5px" color="green">
                  Sign in
                </Typography>
              </Link>
            </Flex>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Register;
